import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../../config/store";
import App from "../App/App";
import { injectStore } from "../../config/axios";
import MetaPixel from "../../config/metaPixel";
import GoogleTag from "../../config/googleTag";
import ChunkErrorBoundary from "../ErrorBoundary/ChunkErrorBoundary";
injectStore(store);

const Root = () => (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter basename="/">
                <ChunkErrorBoundary>
                    <MetaPixel />
                    <GoogleTag />
                    <App />
                </ChunkErrorBoundary>
            </BrowserRouter>
        </PersistGate>
    </Provider>
)

export default Root;