import { useState } from 'react';
import styles from './toggleButton.module.scss';
import { Skeleton, ToggleButton, Tooltip } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButtonSharedProps } from '../../interfaces/components';
import { capitalize } from '../../helpers/app';

const ToggleButtonShared = ({
    onPress = () => { },
    type = "single",
    size = "normal",
    children,
    className,
    disabled = false,
    selected = false,
    exclusive = false,
    value,
    loading = false,
    toolTipTitle = ""
}: ToggleButtonSharedProps) => {
    const [selectedValue, setSelectedValue] = useState<boolean>(selected);

    const handleChange = () => {
        setSelectedValue(!selectedValue);
        if (onPress) onPress(value);
    }

    return (
        <>
            {
                type === "single"
                    ?
                    loading
                        ? <Skeleton animation="wave" variant="circular" className={`${styles.toggleButtonSingle} ${styles.skeleton}`} />
                        :
                        <Tooltip disableFocusListener title={toolTipTitle} arrow placement="bottom">
                            <ToggleButton
                                value="check"
                                selected={selected}
                                className={`${styles.toggleButtonSingle} ${className}`}
                                disabled={disabled}
                                classes={{
                                    selected: styles.selected
                                }}
                                onChange={handleChange}
                            >
                                {children}
                            </ToggleButton>
                        </Tooltip>
                    :
                    loading
                        ? <Skeleton animation="wave" variant="rounded" className={`${styles.toggleButtonGroup} ${size === "small" && styles.small} ${styles.skeleton}`} />
                        :
                        <ToggleButtonGroup
                            value={value}
                            exclusive={exclusive}
                            onChange={(_, value) => value && onPress(value)}
                            className={`${styles.toggleButtonGroup} ${size === "small" && styles.small} ${className}`}
                        >
                            {
                                children && children.map((option: any, index: number) => (
                                    <ToggleButton
                                        key={index}
                                        value={option?.value}
                                        classes={{
                                            root: styles.input,
                                            selected: styles.selected
                                        }}
                                    >
                                        {capitalize(option?.label)}
                                    </ToggleButton>
                                ))
                            }
                        </ToggleButtonGroup>
            }
        </>
    );
};

export default ToggleButtonShared;