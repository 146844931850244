import moment from 'moment';
import 'moment/locale/es';
import { CheckerTypes, DocumentsTypes } from '../interfaces/types';
import carData from '../constants/carData.json';

const isWeekend = (day: number) => {
    return day % 6 === 0;
}

export const nextDatesAvailable = (weeks = 1) => {
    let days = [];
    for (let i = 3; i <= 7 * weeks; i++) {
        let day = moment().add(i, 'days');
        
        const weekend = isWeekend(day.day());
        if (!weekend) {
            days.push({
                number: day.format('DD'),
                day: day.format('dddd'),
                month: day.format('MMM'),
                value: new Date(day.toDate())
            });
        }
    }
    return days;
}

export const addPointsInText = (number: string | number | undefined) => {
    if (!number) return "0";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
};

export const removePointsInText = (number: string | number | undefined) => {
    if (!number) return "0";
    return number.toString().replace(/\./g, '');
};

export const capitalize = (word: string | undefined) => {
    if (!word) return;

    const lowecaseWord = word.toLowerCase();
    const firstCapitalLetter = word.charAt(0).toUpperCase();
    const capitalizeWord = firstCapitalLetter + lowecaseWord.slice(1)
    return capitalizeWord;
};

export const parseToRut = (identifier: any) => {
    if (identifier.length === 9) {
        var parsedIdentifier = identifier.substring(0, identifier.length - 1) + "-" + identifier.substring(identifier.length - 1);
        return String(parsedIdentifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    else {
        return String(identifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    }
};

export const formatInput = (value: any, symbol?: string) => {
    var num = value.replace(/\./g, ''); // Quita los puntos
    var onlyNumber = num;

    if (isNaN(num)) { // Si tiene letra
        onlyNumber = num.replace(/[^\d\.]*/g, ''); // Quita las letras
        num = onlyNumber;
    }

    // Agrega puntos correctamente
    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
    num = num.split('').reverse().join('').replace(/^[\.]/, '');
    if (symbol) {
        num = symbol + num;
    }
    var formattedNumber = num;

    return {onlyNumber, formattedNumber};
}

export const clean = (rut: string) => {
    return typeof rut === 'string'
        ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
        : ''
}

export const validateRut = (rut: string) => {
    if (typeof rut !== 'string') {
        return false
    }

    // if it starts with 0 we return false
    // so a rut like 00000000-0 will not pass
    if (/^0+/.test(rut)) {
        return false
    }

    if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
        return false
    }

    rut = clean(rut)

    let t = parseInt(rut.slice(0, -1), 10)
    let m = 0
    let s = 1

    while (t > 0) {
        s = (s + (t % 10) * (9 - (m++ % 6))) % 11
        t = Math.floor(t / 10)
    }

    const v: string = s > 0 ? '' + (s - 1) : 'K'
    return v === rut.slice(-1)
};

export const formatRut = (rut: string, options = {
    dots: true
}) => {
    rut = clean(rut)
  
    let result
    if (options.dots) {
        result = rut.slice(-4, -1) + '-' + rut.substring(rut.length - 1)
        for (let i = 4; i < rut.length; i += 3) {
            result = rut.slice(-3 - i, -i) + '.' + result
        }
    } else {
        result = rut.slice(0, -1) + '-' + rut.substring(rut.length - 1)
    }

    return result;
};

export const restoreDocumentObject = (key: DocumentsTypes, oldObject: any, newData: any) => {
    const RESTORE_DOCUMENT_OBJECT = {
        identity_card: {
            ...oldObject,
            identity_card: oldObject.identity_card.map((obj: any) => ((obj.id ?? obj._id) === newData) ? { ...obj, toDelete: false } : obj)
        },
        salary_settlements: {
            ...oldObject,
            salary_settlements: oldObject.salary_settlements.map((obj: any) => ((obj.id ?? obj._id) === newData) ? { ...obj, toDelete: false } : obj)
        },
        previred_certificate: {
            ...oldObject,
            previred_certificate: oldObject.previred_certificate.map((obj: any) => ((obj.id ?? obj._id) === newData) ? { ...obj, toDelete: false } : obj)
        },
        domicile_certificate: {
            ...oldObject,
            domicile_certificate: oldObject.domicile_certificate.map((obj: any) => ((obj.id ?? obj._id) === newData) ? { ...obj, toDelete: false } : obj)
        },
        bank_data: {
            ...oldObject,
            bank_data: oldObject.bank_data.map((obj: any) => ((obj.id ?? obj._id) === newData) ? { ...obj, toDelete: false } : obj)
        },
        references: {
            ...oldObject,
            references: oldObject.references.map((obj: any) => ((obj.id ?? obj._id) === newData) ? { ...obj, toDelete: false } : obj)
        }
    };
    return RESTORE_DOCUMENT_OBJECT[key];
};

export const filterDocumentObject = (key: DocumentsTypes, oldObject: any, newData: any) => {
    const FILTER_DOCUMENT_OBJECT = {
        identity_card: {
            ...oldObject,
            identity_card: oldObject.identity_card.filter((obj: any) => (obj._id ?? obj.id) !== newData._id)
        },
        salary_settlements: {
            ...oldObject,
            salary_settlements: oldObject.salary_settlements.filter((obj: any) => (obj._id ?? obj.id) !== newData._id)
        },
        previred_certificate: {
            ...oldObject,
            previred_certificate: oldObject.previred_certificate.filter((obj: any) => (obj._id ?? obj.id) !== newData._id)
        },
        domicile_certificate: {
            ...oldObject,
            domicile_certificate: oldObject.domicile_certificate.filter((obj: any) => (obj._id ?? obj.id) !== newData._id)
        },
        bank_data: {
            ...oldObject,
            bank_data: oldObject.bank_data.filter((obj: any) => (obj._id ?? obj.id) !== newData._id)
        },
        references: {
            ...oldObject,
            references: oldObject.references.filter((obj: any) => (obj._id ?? obj.id) !== newData._id)
        }
    };
    return FILTER_DOCUMENT_OBJECT[key];
};

export const updateDocumentObject = (key: DocumentsTypes, oldObject: any, newData: any) => {
    const UPDATED_OBJECT = {
        identity_card: {
            ...oldObject,
            identity_card: oldObject.identity_card.concat(newData)
        },
        salary_settlements: {
            ...oldObject,
            salary_settlements: oldObject.salary_settlements.concat(newData)
        },
        previred_certificate: {
            ...oldObject,
            previred_certificate: oldObject.previred_certificate.concat(newData)
        },
        domicile_certificate: {
            ...oldObject,
            domicile_certificate: oldObject.domicile_certificate.concat(newData)
        },
        bank_data: {
            ...oldObject,
            bank_data: oldObject.bank_data.concat(newData)
        },
        references: {
            ...oldObject,
            references: oldObject.references.concat(newData)
        }
    };
    return UPDATED_OBJECT[key];
};

export const generateNewDocumentObject = (key: DocumentsTypes, oldObject: any, newData: any) => {
    const NEW_DOCUMENT_OBJECT = {
        identity_card: {
            ...oldObject,
            identity_card: newData
        },
        salary_settlements: {
            ...oldObject,
            salary_settlements: newData
        },
        previred_certificate: {
            ...oldObject,
            previred_certificate: newData
        },
        domicile_certificate: {
            ...oldObject,
            domicile_certificate: newData
        },
        bank_data: {
            ...oldObject,
            bank_data: newData
        },
        references: {
            ...oldObject,
            references: newData
        }
    };
    return NEW_DOCUMENT_OBJECT[key];
};

export const getYears = (limitYear: number = 5) => {
    const now = new Date().getUTCFullYear();
    const years = Array((now + 1) - (now - limitYear)).fill('').map((v, idx) => now - idx) as Array<number>;
    if (new Date().getMonth() > 8) { 
        years.unshift(now + 1); 
    }
    return years;
}

export const copyToClipboard = (text: any) => {
    return navigator.clipboard.writeText(text);
}

export const checkerType = (value: any, checker: CheckerTypes = "onlyLetters") => {
    if(checker === "onlyLetters"){
        return /^[a-zA-Z\s]*$/.test(value);
    }
    if(checker === "onlyNumbers"){
        return /^[0-9\s]*$/.test(value);
    }
    return true;
}

export const foundCarByBrand = (brand: string) => {
    return carData.find((item: any) => brand.toLowerCase().indexOf(item?.name.toLowerCase()) > -1);
}

const padTo2Digits = (num: any) => {
    return num.toString().padStart(2, '0');
}

export const formatDate = (date: any) => {
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate())
    ].join('-');
}

export const numberFormatter = (num: number, digits: number) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const isIOS = () => {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
        'MacIntel',
        'Macintosh',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

export const boldBetweenAsterisks = (text: string | undefined) => {
    if (!text) return "";
    return text.replace(/\*(.*?)\*/g, `<b>$1</b>`)
};

export const checkCreditEligibility = (publicationYear: string) => {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    if (currentMonth >= 9) {
        currentYear += 1;
    }
    const yearNumber = parseInt(publicationYear, 10);
    return ((currentYear - yearNumber) <= 10);
}